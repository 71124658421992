import axios from "axios";
import axiosInstance from "./Api";

export default {
  async loadItemType() {
    let response = await axiosInstance().get(`/dropdown/getItemType`);
    return response;
  },

  async loadCustomisations() {
    let response = await axiosInstance().get(`/dropdown/getCustomisations`);
    return response;
  },

  async loadAllCountries() {

    return new Promise((resolve) => {
      axios
        .get(
          `https://revivify-new.s3.ap-south-1.amazonaws.com/json/countries.json`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          resolve([]);
        });
    });
  },
  async loadState() {
    return new Promise((resolve) => {
      axios
        .get(
          `https://revivify-new.s3.ap-south-1.amazonaws.com/json/states.json`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          resolve([]);
        });
    });
  },
  async loadCity() {
    return new Promise((resolve) => {
      axios
        .get(
          `https://revivify-new.s3.ap-south-1.amazonaws.com/json/cities.json`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          resolve([]);
        });
    });
  },
  async addItemType(payload) {
    let response = await axiosInstance().post(`/dropdown/addItemType`, payload);
    if (response.data.data != null) {
      return await axiosInstance().get(`/dropdown/getItemType`);
    } else {
      return response;
    }
  },
};
