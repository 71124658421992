import axiosInstance from "./Api";

export default {

  async loadAddress(uid) {
    console.log("get_address", uid);
    let response = await axiosInstance().get(`/address/${uid}`);
    console.log("addresses :: " + JSON.stringify(response.data.data));
    return response;
  },

  async getSellerAddress(uid) {
    let response = await axiosInstance().get(`/address/getSingleSeller/${uid}`);
    console.log("get addresses :: " + JSON.stringify(response.data.data));
    return response;
  },

  async deleteAddress(payload, uid) {
    let user_id = parseInt(uid);
    const data = {
      add_id: parseInt(payload),
    };
    let deleteResponse = await axiosInstance().delete(`/address/`, { data });
    console.log(
      "delete address :: " + JSON.stringify(deleteResponse.data.data)
    );

    if (deleteResponse.data.data != null) {
      return await axiosInstance().get(`/address/${user_id}`);
    } else {
      return deleteResponse;
    }

  },

  async addUpdateAddress(payload, uid) {
    let response = await axiosInstance().post(`/address/`, payload);
    console.log("add address :: " + JSON.stringify(response.data.data));
    let user_id = parseInt(uid);

    if (response.data.data != null) {
      return await axiosInstance().get(`/address/${user_id}`);
    } else {
      return response;
    }
  },


};
