<template>
  <div>
    <b-button class="btn-primary" variant="primary" v-on:click="addAddress(0)">
      <feather-icon icon="AddIcon" />
      Add New Address
    </b-button>
    <hr />
    <b-row>
      <b-col
        md="4"
        v-for="(address, index) in addressData"
        :key="index + '_address'"
      >
        <b-card>
          <!-- <h1>{{ address.add_location_type }}</h1> -->
          <b-row>
            <!-- <b-col sm="6" md="6" lg="6">
              <h4>{{ address.add_location_type }}</h4>
            </b-col> -->
            <b-col sm="12" md="12" lg="12">
              <b-button
                class="rounded-circle btn-icon"
                v-on:click="editAddress(address)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                class="ml-2 rounded-circle btn-icon"
                v-on:click="deleteAddress(`${address.add_id}`)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col md="12">
              <h6>
                Address:
                {{
                  address.add_address_line_1 +
                  ", " +
                  address.add_address_line_2 +
                  ", " +
                  address.add_address_line_3 +
                  ", Landmark: " +
                  address.add_landmark +
                  ", " +
                  address.add_city +
                  ", " +
                  address.add_state +
                  ", " +
                  address.add_country +
                  ", "
                }}
              </h6>
            </b-col>
          </b-row>
          <hr />
          <b-col md="6">
            <p>Name:<br />{{ address.add_name }}</p>
          </b-col>
          <b-col md="6">
            <p>Phone:<br />{{ address.add_phone }}</p>
          </b-col>
        </b-card>
      </b-col>

      <!-- modal -->
      <b-modal
        id="modal-add-address"
        ref="my-modal"
        title="Add/Update Address"
        ok-title="Submit"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <validation-observer ref="addressForm">
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-row>
              <b-col md="12">
                <label> Address Label </label>
                <validation-provider
                  #default="{ errors }"
                  name="Address Label"
                  rules="required"
                >
                  <b-form-group class="mb-0" label-for="type-name">
                    <b-form-input
                      id="type-name"
                      list="my-list-id"
                      v-model="addressTypeModel"
                      required
                    />
                    <datalist id="my-list-id">
                      <option
                        v-for="autoType in addressTypeName"
                        :key="autoType"
                      >
                        {{ autoType }}
                      </option>
                    </datalist>
                  </b-form-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <b-form-group class="mt-2" label="Select Address Type">
              <validation-provider
                #default="{ errors }"
                name="Address Types"
                rules="required"
              >
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="addressServiceSelected"
                  :options="addressServiceOptions"
                  name="flavour-1"
                  class="demo-inline-spacing"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-row>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    ref="nameRef"
                    id="name-input"
                    v-model="nameModel"
                    autocomplete="off"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="12">
                <b-form-group label-for="account-mobile">
                  <b-row>
                    <b-col md="5">
                      <label> Phone Country Code </label>

                      <validation-provider
                        #default="{ errors }"
                        name="Dial Code"
                        rules="required"
                      >
                        <b-form-select
                          v-model="coutryCodeSelected"
                          @change="chooseCountryPhoneCode"
                        >
                          <b-form-select-option
                            v-for="(item, index) in countryCode"
                            :key="index"
                            :value="item.phone_code"
                            :disabled="item.phone_code === 'disabled'"
                          >
                            {{ item.name }}
                            {{
                              item.phone_code === "disabled"
                                ? ""
                                : "(" + removePlus(item.phone_code) + ")"
                            }}
                          </b-form-select-option>
                        </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col md="7">
                      <label> Mobile </label>
                      <validation-provider
                        #default="{ errors }"
                        name="Mobile"
                        rules="required"
                      >
                        <b-form-input
                          id="phone-input"
                          v-model="phoneModel"
                          required
                          name="phone"
                          placeholder="Mobile"
                          type="number"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12" lg="12" sm="12">
                <label> Address 1 </label>
                <validation-provider
                  #default="{ errors }"
                  name="Address 1"
                  rules="required"
                >
                  <b-form-input
                    id="address-1-input"
                    v-model="address1Model"
                    :state="address1State"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="12" lg="12" sm="12">
                <label> Address 2 </label>
                <validation-provider
                  #default="{ errors }"
                  name="Address 2"
                  rules="required"
                >
                  <b-form-input
                    id="address-2-input"
                    v-model="address2Model"
                    :state="address2State"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="12" lg="12" sm="12">
                <label> Address 3 </label>
                <b-form-input
                  id="address-3-input"
                  v-model="address3Model"
                  :state="address3State"
                  autocomplete="off"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <label> Country </label>
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <v-select
                    id="countries-select"
                    v-model="countryModel"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="countries"
                    @input="showState"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <label> State </label>
                <validation-provider
                  #default="{ errors }"
                  name="State"
                  rules="required"
                >
                  <v-select
                    id="countries-select"
                    v-model="state_model"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :disabled="state_list.length == 0"
                    :options="state_list"
                    @input="showCity"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="12">
                <label> City </label>
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  rules="required"
                >
                  <v-select
                    id="city-select"
                    v-model="city_model"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :disabled="city_list.length == 0"
                    :options="city_list"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col md="6">
                <b-form-group
                  label="Landmark"
                  label-for="landmark-input"
                  invalid-feedback="Landmark is required"
                >
                  <b-form-input
                    id="landmark-input"
                    autocomplete="off"
                    v-model="landmarkModel"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <label>PIN Code</label>
                <validation-provider
                  #default="{ errors }"
                  name="PIN Code"
                  rules="required"
                >
                  <b-form-input
                    id="pin-input"
                    autocomplete="off"
                    v-model="pinModel"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </form>
        </validation-observer>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardText,
  BModal,
  VBModal,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import AddressServices from "@/apiServices/AddressServices";
import DropDownServices from "@/apiServices/DropDownServices";
import feather from "feather-icons";
import swal from "sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import storageService from "@/apiServices/storageService";
import axios from "axios";
import "cleave.js/dist/addons/cleave-phone.us";
import { country_code } from "./countries_json";

window.swal = swal;
//import { addressDeleteConfirm } from "./sweetAlertCode";

export default {
  components: {
    BCardText,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BModal,
    VBModal,
    BForm,
    BFormGroup,
    BCardCode,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelect,
    BFormSelectOption,

    vSelect,
    feather,
    FeatherIcon,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    //const addressData = [];
    // return { addressData };
  },
  beforeMount() {
    this.loadAddress();
    //this.loadCountry();
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,

      addressID: 0, //If 0 Add Address - Else 1,2,3 etc Update Address of ID

      address1Model: "",
      address1State: null,

      address2Model: "",
      address2State: null,

      address3Model: "",
      address3State: null,

      addressTypeModel: "",
      addressTypeState: null,

      addressSercviceTypeModel: "",
      addressSercviceTypeState: null,

      nameModel: "",
      nameState: null,

      phoneModel: "",
      phoneState: null,

      countryModel: "",
      countrySelectedName: "",
      countryState: null,

      landmarkModel: "",
      landmarkState: null,

      pinModel: "",
      pinState: null,

      typeState: null,

      addressData: [],
      // option: ["HOME", "OFFICE"],

      // optionType: [
      //   "Same for All",
      //   "Billing Address",
      //   "Pickup Address",
      //   "Delivery Address",
      // ],

      addressServiceSelected: [],
      addressServiceOptions: [
        { text: "Billing ", value: "billing", keyName: "add_shipping" },
        {
          text: "Shippping ",
          value: "shipping",
          keyName: "add_billing",
        },
        { text: "Pickup ", value: "pickup", keyName: "add_pickup" },
        {
          text: "Delivery ",
          value: "delivery",
          keyName: "add_delivery",
        },
      ],

      countries: [],
      state_list: [],
      state_model: "",

      city_list: [],
      city_model: "",

      coutryCodeSelected: "",
      countryCode: country_code,

      addressTypeName: ["Home", "Work", "Office"],
      getType: "",
    };
  },
  methods: {
    removePlus(code) {
      return code.replace("+", "");
    },
    chooseCountryPhoneCode(event) {
      let selectedCountry = this.countries.filter((c) => c.phone_code == event);
      if (selectedCountry.length) {
        this.countryModel = selectedCountry[0].name;
        this.showState(selectedCountry[0]).catch(console.log);
      }
    },
    async showCity(state_obj) {
      this.city_list = [];
      this.city_model = "";
      let response = await DropDownServices.loadCity();
      //console.log(response.data.length, "get city");
      this.city_list = response.data.filter((x) => {
        return x.state_id == state_obj.id;
      });
      //console.log(this.city_list.length, "set city");
    },
    async showState(country_obj) {
      //console.log("AHAY", country_obj.name);
      this.countrySelectedName = country_obj.name;

      this.state_list = [];
      this.state_model = "";
      let response = await DropDownServices.loadState();
      //console.log(response.data.length, "before showState");
      this.state_list = response.data.filter((x) => {
        return x.country_id == country_obj.id;
      });
      //console.log(this.state_list.length, "after showState");
    },
    async loadCountry() {
      let response = await DropDownServices.loadAllCountries();
      //console.log(response, "loadCountry");
      this.countries = response.data;
    },
    loadAddress() {
      //console.log("Loading Address From Function");
      const profileData = storageService.UserData.getUserProfile();
      let uid = JSON.parse(profileData);
      console.log(uid);

      AddressServices.loadAddress(uid.user_id)
        .then((response) => {
          //console.log("UI Address: " + JSON.stringify(response.data.data));
          this.addressData = response.data.data;
          console.log(this.addressData);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          //console.error("There was an error!", error);
        });
    },

    deleteAddress(event) {
      new swal({
        title: "Are you sure want to delete?",
        text: "If you delete this addres you wont be able to recover it again.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes! Delete",
        closeOnConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteAdd(event);
        }
      });
    },

    addAddress() {
      this.loadCountry();
      this.$refs["my-modal"].toggle("#toggle-btn");
      this.addressID = 0;
    },

    editAddress(aid) {
      this.$refs["my-modal"].toggle("#toggle-btn");

      //console.log("t", JSON.stringify(aid));

      this.addressID = parseInt(aid.add_id);
      this.addressTypeModel = aid.add_location_type;
      this.nameModel = aid.add_person_name;
      this.phoneModel = aid.add_phone;
      this.flatnoModel = aid.add_flat_house_no;
      this.alternativePhoneModel = aid.add_altr_phone;
      this.countryModel = aid.add_country;
      this.landmarkModel = aid.add_landmark;
      this.pinModel = aid.add_pin_code;
      this.addressModel = aid.add_address;
    },

    deleteAdd(id) {
      const profileData = storageService.UserData.getUserProfile();
      let uid = JSON.parse(profileData);
      AddressServices.deleteAddress(id, uid.user_id)
        .then((response) => {
          //console.log("UI Address: " + JSON.stringify(response.data.data));
          //this.addressData = response.data.data;
          //console.log(this.addressData);
          this.loadAddress();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();

      /* this.addressTypeState = valid;
      this.nameState = valid;
      this.phoneState = valid;
      this.countryState = valid;
      this.alternativePhoneState = valid;
      this.landmarkState = valid;
      this.pinState = valid;
      */

      return valid;
    },
    resetModal() {
      this.addressTypeModel = "";
      this.nameModel = "";
      this.phoneModel = "";
      this.flatnoModel = "";
      this.alternativePhoneModel = "";
      this.countryModel = "";
      this.landmarkModel = "";
      this.pinModel = "";
      this.addressModel = "";

      this.addressTypeState = null;
      this.nameState = null;
      this.phoneState = null;
      this.flatnoState = null;
      this.alternativePhoneState = null;
      this.countryState = null;
      this.landmarkState = null;
      this.pinState = null;
      this.addressState = null;
    },

    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },

    handleSubmit() {
      this.$refs.addressForm.validate().then((success) => {
        if (success) {
          // Exit when the form isn't valid
          const profileData = storageService.UserData.getUserProfile();
          let uid = JSON.parse(profileData);

          let obj = {};
          this.addressServiceOptions.map((x) => {
            obj[x.keyName] = 0;
            let findIndex = this.addressServiceSelected.indexOf(x.value);
            if (findIndex >= 0) {
              obj[x.keyName] = 1;
            }
          });

          const sendData = {
            add_uid: uid.user_id,
            add_id: this.addressID,
            add_type_name: this.addressTypeModel,
            add_choose_service: this.addressServiceSelected,
            add_name: this.nameModel,
            add_phone_code: this.coutryCodeSelected,
            add_phone: this.phoneModel,
            add_address_line_1: this.address1Model,
            add_address_line_2: this.address2Model,
            add_address_line_3: this.address3Model,
            add_country: this.countrySelectedName,
            add_state: this.state_model.name,
            add_city: this.city_model.name,
            add_landmark: this.landmarkModel,
            add_pin_code: this.pinModel,
            ...obj,
          };

          AddressServices.addUpdateAddress(sendData, uid.user_id)
            .then((response) => {
              //this.addressData = response.data.data;
              //console.log(this.addressData);
              this.$refs["my-modal"].toggle("#toggle-btn");
              this.loadAddress();
            })
            .catch((error) => {
              this.errorMessage = error.message;
              console.error("There was an error!", error);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.swal2-html-container {
  color: white;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
